import { Heading, Flex, Text, Card } from '@aws-amplify/ui-react';
import React from 'react';


class WorkHistory extends React.Component {
    render() {
        return <Card variation='elevated' key={this.props.index}>
            <Flex direction="row" alignItems="flex-start">

                <Flex
                    direction="column"
                    alignItems="flex-start"
                >
                    <Flex >
                        <Heading level="6" color={"#fbd8bf"}><u>{this.props.item.role}</u></Heading><Heading level="6" color={this.props.item.company_color}>{this.props.item.company}</Heading>
                    </Flex>
                    <Text > <i>{this.props.item.timeframe}</i></Text>

                    <Text as="span" textAlign={"left"}>
                        {this.props.item.description}
                    </Text>
                    <Text as="span" textAlign={"left"}>
                        Key Projects:
                        {this.props.item.key_projects.map((project, index) => (
                            <ul key={index}>{project}</ul>
                        )
                        )
                        }
                    </Text>
                </Flex>
            </Flex>
        </Card>;
    }
}

export default WorkHistory;