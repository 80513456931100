import { Heading, Flex, Text, Collection, Image, View, ThemeProvider, Divider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import WorkHistory from './WorkHistoryCard';
import React from 'react';

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tokens: props.tokens, loaded: false }
  }

  render() {
    const work = [
      {
        role: 'Senior Systems Development Engineer',
        company: 'Amazon Web Services',
        description: 'Systems Engineer in Networking Core - Working in Backbone, Enterprise and Regional Edge (BERE) Networking Focusing on Network Verification and Reasoning',
        timeframe: "2022-present",
        key_projects: [
          "..."
        ],
        company_color: "#ff9900",
      },
      {
        role: 'Senior Application Architect',
        company: 'Amazon Web Services',
        description: 'Senior Application Architect in the Global speciality practise. Focused on assisting customers with greenfield application design and enabling them to modernise existing applications to fully leverage the cloud.',
        timeframe: "2021-2022",
        company_color: "#ff9900",
        key_projects: [
          "In-car timing and racing software for a leading luxury car manufacturer",
          "Serverless-first architecture for the UKs largest electrical wholesaler",
          "Serverless data mesh for one of the world largest agricultural suppliers"
        ],
      },
      {
        role: 'Senior Software Developer',
        company: 'Skyscanner',
        description: 'Software Developer in the Production Platform and Data Transformation Tribes. SME for Skyscanner\'s API Gateway solution, Public Facing DNS and additional core infrastructure.',
        timeframe: "2018-2021",
        company_color: "#0770e3",
        key_projects: [
          "Skyscanner API Gateway",
          "Public DNS Pipeline",
          "Multi-Account adoption",
          "Multi-Account CDK Pipelines",
        ],
      },
      {
        role: 'Software Engineer',
        company: 'Gael Ltd / Ideagen Ltd',
        description: 'Software Developer working on the Cloud Native GRC Software Coruson. Worked on the messaging bus, event store and implemented a new permissions model for increased page load performance. ',
        timeframe: "2014-2018",
        company_color: "#FF4612",
        key_projects: [
          "Security Model 2.0",
          "ECCAIRS Reporting",
          "Event Store Optimisation",
          "Security Configuration",
        ],
      }
    ]
    const theme = {
      name: "home-theme",
      tokens: {
        components: {
          card: {
            backgroundColor: { value: "#232f3e" },
            elevated: {
              backgroundColor: { value: '#161E2D' },
              boxShadow: { value: '{shadows.large}' },
            },
          },
          heading: {
            color: { value: "white" },
            5: {
              color: { value: "blue" },

            },
            3: {
              color: { value: "blue" },

            },
          },
          text: {
            color: { value: "white" }

          }
        }
      }
    }

    return (
      <ThemeProvider theme={theme} colorMode="light" >
        <View>
          <Flex direction="column" alignItems="center" width="100%" display="block">
            <Heading level={1} >Stewart Wallace</Heading>
            <Image src='/profile.jpeg' id="profile-pic"></Image>
            <Divider />
            <Text>Is mise Stiubhart!</Text>
            <Text> I am a {work[0].role} at {work[0].company} </Text>
            <Heading level="4" textAlign={"left"}>Work History</Heading>
            <Collection
              items={work}
            >
              {(item, index) => (
                <WorkHistory key={index} index={index} item={item} />
              )}

            </Collection>
          </Flex>
        </View >
      </ThemeProvider >
    );
  }
}
